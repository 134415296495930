<template>
  <b-container
    fluid
    v-bind:class="{ 'p-0 overflow-hidden': isIFrame }"
    class="min100"
    v-bind:style="{ color: textColor, backgroundColor: backgroundColor }"
  >
    <!--<div id="start" />-->

    <b-row v-if="!isIFrame" class="header">
      <b-col>
      <h1 v-if="!logoUrl">{{ $route.params.tenantname }}</h1>
      <a :href="logoLink" target="_blank"><b-img id="logo" v-if="logoUrl" :src="logoUrl" fluid /></a>
      </b-col>
    </b-row>

    <router-view />

    <b-row v-if="!isIFrame" class="footer">
      <b-col class="text-center">
      <div v-if="privacyPolicyUrl"><small><a target="_blank" :href="privacyPolicyUrl">{{ privacyPolicyText || privacyPolicyUrl }}</a></small></div>
      <div class="mt-2 pa-3" v-if="showConversoFooter">
        <div><small>Powered by</small></div>
        <a target="_blank" href="https://converso.cloud">
          <b-img class="" style="max-width: 120px" :src="conversoLogoSrc" fluid alt="Converso Logo"></b-img>
        </a>
      </div>
      <div class="mt-2" v-if="showConversoFooter == 'dark'">Powered by <a target="_blank" href="https://converso.cloud">Converso</a></div>
      <div class="mt-2" v-if="customFooterHtml" v-html="customFooterHtml"></div>  
    </b-col>
    </b-row>

  </b-container>
</template>

<style>
.home {
  width: 100%;
  height: 100%;
}
.header {
  text-align: center;
  padding: 1.5em;
}
.min100 {
  min-height: 100%;
}
.footer {
  text-align: center;
  padding: 1.5em;
}
#start {
  margin: 0;
  padding: 0;
  height: 1px;
  visibility: hidden;
}
#logo {
  max-width: 70%;
  max-height: 10vh;
}
.conversoLogoBlack path {
  fill: #000;
}
.conversoLogoWhite path {
  fill: #fff;
}
.conversoLogoBlu path {
  fill: #000;
}
</style>

<script>
import * as fb from "./../firebase";

export default {
  data() {
    return {
      customization: null,
      isIFrame: false,
    };

  },
  computed: {
    backgroundColor() {
      return this.customization
        ? this.customization.background_color
        : "#ffffff";
    },
    logoUrl() {
      return this.customization ? this.customization.logo_url : false;
    },
    logoLink() {
      return this.customization ? this.customization.logo_link : false;
    },
    privacyPolicyText() {
      return this.customization ? this.customization.privacy_policy_text : "";
    },
    privacyPolicyUrl() {
      return this.customization ? this.customization.privacy_policy_url : "";
    },
    textColor() {
      return this.customization ? this.customization.text_color : "#000000";
    },
    buttonColor() {
      return this.customization ? this.customization.button_color : "#000000";
    },
    showConversoFooter() {
      return this.customization && this.customization.conversoFooter ? this.customization.conversoFooter : false;
    },
    customFooterHtml() {
      return this.customization ? this.customization.customFooterHtml : false;
    },
    conversoLogoSrc() {
      switch (this.showConversoFooter) {
        case 'white':
          return '/assets/logo-converso.svg';
        case 'black':
          return '/assets/logo-converso-black.svg';
        case 'blue':
          return '/assets/logo-converso-blue.svg';
        default:
          return '/assets/logo-converso.svg' 
    }
    }
  },
  mounted() {
    this.isIFrame = window.location !== window.parent.location
    this.getCustomization();
  },
  methods: {
    getCustomization() {
      var docRef = fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("customization")
        .doc("0");

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.customization = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("Customization not found");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },
  },
};
</script>

